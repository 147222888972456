import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import React from "react";

interface SalaryHistory {
  salary: number;
  dateChanged: { $date: string };
  _id: { $oid: string };
}

const columns: ColumnsType<any> = [
  {
    title: "Salary (PKR)",
    dataIndex: "salary",
    key: "salary",
  },
  {
    title: "Date Changed",
    dataIndex: "dateChanged",
    key: "dateChanged",
    render: (dateChanged: string) => dayjs(dateChanged).format("DD/MM/YYYY"),
  },
];

const SalaryHistoryTable: React.FC<{
  data: SalaryHistory[];
  isLoading: boolean;
}> = ({ data, isLoading }) => (
  <Table loading={isLoading} dataSource={data} columns={columns}  pagination={false}/>
);

export default SalaryHistoryTable;
