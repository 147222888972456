import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BankDetailsAPI } from '../../../(apis)/(shared-apis)/bank-details';
import { ButtonFill, ButtonOutlined } from '../../../shared-components/ui/CustomButtons';
import { customAPIHandler } from '(apis)/api-interface/api-handler';

export default function AddToBankModal(props:any) {

  const [bankDetails, setBankDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState('');

  const [amountDeposited, setAmountDeposited] = useState(0);
  useEffect(() => {
    setAmountDeposited(props.amount);
  }, [props.amount])

  const GetDetails = async () => {
    setLoading(true);
    try {
      const response = await customAPIHandler("getBankDetails", null, null, props.entity);
      setBankDetails(response.data.bankDetails);
    }
    catch (error) {
      console.error("Error getting bank details data:", error);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    GetDetails();
  }, [])

  const [selectedBank, setSelectedBank] = useState(null);

  const Submit = async () => {
    if (!selectedBank) {
      toast.error("Please select a bank to add to");
      return;
    }
    if (amountDeposited<= 0) {
      toast.error("Amount is not valid");
      return;
    }
    if (!props.orderid || props.orderid === "") {
      toast.error("Order ID is not valid");
      return;
    }


    try {
      const History = {
        Amount: amountDeposited,
        Notes: note,
        OrderID: props.orderid,
        PaymentImage: props.image,
        SubmittedBy: props.user.username,
        bankDetailsId: selectedBank
      }
      const data = {
        OrderID: props.orderid,
        AmountDeposited: amountDeposited,
        History: History
      }
      const response = await customAPIHandler("addAmountToBank", selectedBank, data, props.entity);
      if (response.data.success) {
        toast.success(response.data.message);
        props.onConfirm();
      }
      else {
        toast.error(response.data.message);
        props.onCancel();
      }
    }
    catch (error) {
      console.error("Error adding amount to bank:", error);
      toast.error("Error adding amount to bank");
    }
  }

  return (
    <div
      id="createProductModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${props.active ? "" : "hidden"} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen backdrop-blur-sm !space-x-0`}
    >
      <div className="relative w-full max-w-2xl max-h-full p-4">
        <div className="relative p-4 bg-white rounded-lg shadow ">
          {loading ? (
            <div className="flex justify-center items-center">
              <div className="w-10 h-10 border-b-2 border-gray-900 rounded-full animate-spin" />
              <div className="ml-2 text-gray-900">Loading...</div>
            </div>
          ) : (
            <div className='flex flex-col justify-between'>
              <div className='grid grid-cols-2 md:grid-cols-3 gap-4'>
                {bankDetails.map((bank, index) => (
                  <div key={index} className={`p-4 rounded-lg shadow overflow-hidden flex flex-col justify-between cursor-pointer
                ${selectedBank === bank._id ? 'border-2 border-blue-500' : 'border border-gray-200'}`}
                    onClick={() => setSelectedBank(bank._id)}
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <h1 className="text-lg font-bold text-gray-900">{bank.Type}</h1>
                        <p className="text-sm text-gray-500">{bank.BankName}</p>
                        <p className="text-sm text-gray-500">{bank.AccountTitle}</p>
                      </div>
                    </div>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500"><span className="font-bold">Account Number:</span> {bank.AccountNumber}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <input type="text" value={note} placeholder='Enter Note' className='h-[50px] w-[50%] rounded-[12px] shadow-2xl mt-6 p-[12px]' onChange={(e) => {
                  setNote(e.target.value);
                }}/>
              </div>
              <div className="mt-4 flex items-center justify-between flex-wrap">
                <div className="flex justify-start items-center gap-1 text-lg text-gray-500">Total Amount: 
                  <input type="number" className={`border border-gray-200 p-1 w-40`} value={amountDeposited} onChange={(e) => setAmountDeposited(parseInt(e.target.value))} />                
                </div>

                <div className="flex items-center justify-start md:justify-end gap-2 mt-4 flex-wrap">
                  <ButtonOutlined
                    handleClick={() => {
                      setSelectedBank(null);
                      props.onCancel();
                    }}
                  >
                    Cancel
                  </ButtonOutlined>
                  <ButtonFill
                    handleClick={Submit}
                  >
                    Add to Bank
                  </ButtonFill>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>

  )
}

