import { Select } from "antd";
import { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { OrderAPI } from "../../../../(apis)/(shared-apis)/order-apis";
import CustomBadges from '../../../../shared-components/ui/CustomBadges';
import { ButtonFill } from '../../../../shared-components/ui/CustomButtons';
import CustomDropDown from '../../../../shared-components/ui/CustomDropDown';
import { EditIcon } from '../../../../shared-components/ui/Icons';
import { apiRequest, customAPIHandler } from "(apis)/api-interface/api-handler";
import { CourseSubscriptionAPI } from "(apis)/(shared-apis)/courses/course-subscriptions";
export default function NewOrderManager() {

    const [originalOrders, setOriginalOrders] = useState([]);
    const [orders, setOrders] = useState([]);
    const [skus, setSkus] = useState([]);
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [totalPaidAmount, setTotalPaidAmount] = useState<number>(0);
    const [totalDiscountAmount, setTotalDiscountAmount] = useState<number>(0);
    const [averageOrderValue, setAverageOrderValue] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState("PRE-MEDICAL");

    const [coursesData, setCoursesData] = useState([]);
    const getCourseData = async () => {
        try {
          const res = await customAPIHandler("getCourses", "", null, selectedCategory);
          if (res?.status === 200) {
            setCoursesData(res?.data?.courses || []);
          }
        } catch (error) {
          console.log("error ", error);
          setCoursesData([]);
        }
      };
    
      useEffect(() => {
        getCourseData().then(() => console.log());
      }, [selectedCategory]);
    
    const [subscriptions, setSubscriptions] = useState([]);

    const fetchOrderDetails = async () => {
        setIsLoading(true);
        try {
            const submittableForm = {
                ...form,
                startDate: new Date(form.startDate).toISOString(),
                endDate: form.endDate && form.endDate !== "" ? new Date(form.endDate).toISOString() : new Date().toISOString(),
            };
            const response = await customAPIHandler("getCoursesStatistics", "", { form: submittableForm }, selectedCategory);
            const data = response.data.orders || [];
            setSkus(response.data.skus || []);
            setSubscriptions(response.data.data || []);
            setOrders(response.data.orders);
            setOriginalOrders(response.data.orders);

            let totalPaid = 0;
            let totalDiscount = 0;
            data.forEach((order: any) => {
                totalPaid += parseInt(order.PaidAmount || 0);
                totalDiscount += parseInt(order.DiscountAmount || 0);
            });
            setTotalPaidAmount(totalPaid);
            setTotalDiscountAmount(totalDiscount);
            setAverageOrderValue(totalPaid / data.length);
            GenerateMonthlyReport();
            GenerateYearlyReport();

        } catch (error) {
            setOrders([]);
            setOriginalOrders([]);
            setSkus([]);
            setSubscriptions([]);
            setTotalPaidAmount(0);
            setTotalDiscountAmount(0);
            setAverageOrderValue(0);            
            console.log("Error fetching order details:", error);
            console.error("Error fetching order details:", error);
        }
        setIsLoading(false);
    };

    const [coupons, setCoupons] = useState([]);
    const GetCoupons = async () => {
        try {
            const response = await customAPIHandler("getCouponCodes", "", null, selectedCategory);
            setCoupons(response?.data?.data.filter((coupon) => coupon.CouponCode) || []);
        } catch (error) {
            setCoupons([]);
            console.error("Error fetching coupons:", error);
        }
    }

    useEffect(() => {
        GetCoupons();
    }, [selectedCategory])



    const getPlanName = (plansIds) => {
        const planNames = [];
        plansIds.forEach((planId) => {
            if (planId.PlanID) {
                const plan = skus.find((sku) => sku._id === planId.PlanID);
                planNames.push(plan?.SKUName || "");
            }
        });
        return planNames.join(", ");
    };

    const GetTime = (date: string) => {
        const d = new Date(date);
        return d.toLocaleTimeString();
    }

    const GetDate = (date: string) => {
        //dd-mm-yyyy
        const d = new Date(date);
        return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
    }

    interface Form {
        coupons: string[];
        courses: string[];
        status: string;
        startDate: string,
        endDate: string;
        type: string;
    }


    const [form, setForm] = useState<Form>({
        coupons: [],
        courses: [],
        status: "All",
        startDate: "2020-01-01",
        endDate: "",
        type: "Course OMS"
    })

    const [monthlyBarData, setMonthlyBarData] = useState([]);
    const [yearlyBarData, setYearlyBarData] = useState([]);

    const Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const GenerateMonthlyReport = () => {
        const monthlyData = [];
        orders.forEach((order) => {
            const month = new Date(order.CreationDate).getMonth();
            const year = new Date(order.CreationDate).getFullYear();
            const monthName = Months[month] + " " + year;
            const index = monthlyData.findIndex((data) => data.month === monthName);
            if (index === -1) {
                monthlyData.push({ month: monthName, months: 1 });
            } else {
                monthlyData[index].months += 1;
            }
        });
        monthlyData.reverse();
        setMonthlyBarData(monthlyData);
    }

    const GenerateYearlyReport = () => {
        const yearlyData = [];
        orders.forEach((order) => {
            const year = new Date(order.CreationDate).getFullYear();
            const index = yearlyData.findIndex((data) => data.year === year);
            if (index === -1) {
                yearlyData.push({ year: year, years: 1 });
            } else {
                yearlyData[index].years += 1;
            }
        });
        yearlyData.reverse();
        setYearlyBarData(yearlyData);
    }

    const DownloadMonthlyReport = () => {
        //create a csv file
        let csv = "Month, Orders\n";
        monthlyBarData.forEach((data) => {
            csv += `${data.month}, ${data.months}\n`;
        });
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'monthly-report.csv';
        hiddenElement.click();
    }

    const DownloadYearlyReport = () => {
        //create a csv file
        let csv = "Year, Orders\n";
        yearlyBarData.forEach((data) => {
            csv += `${data.year}, ${data.years}\n`;
        });
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'yearly-report.csv';
        hiddenElement.click();
    }

    const GetSubscriptions = (order) => {
        const subscriptionsFound = [];
        order.subscriptions.forEach((subscriptionId) => {
            const subscription = subscriptions.find((sub) => sub._id === subscriptionId)
            if (subscription) {
                subscriptionsFound.push(subscription);
            }
        });
        order.planCourses.forEach((course) => {
            course.courseIds.forEach((courseId) => {
                const subscription = subscriptions.find((sub) => sub.CourseID === courseId)
                if (subscription) {
                    subscriptionsFound.push(subscription);
                }
            })
        })
        return subscriptionsFound[0] ? subscriptionsFound[0] : {};
    }


    const DownloadCSV = () => {
        //create a csv file
        let csv = "Date of Order, Time of Order, Customer Name, Coupon Used, Products, Order Status, Subscription, Subscription Status, Total Amount\n";
        orders.forEach((order) => {
            csv += `${GetDate(order.CreationDate)}, ${GetTime(order.CreationDate)}, ${order.UserName}, ${order.CouponCode}, ${getPlanName(order.plans)}, ${order.OrderStatus}, ${GetSubscriptions(order)?.SubscriptionName}, ${GetSubscriptions(order)?.SubscriptionStatus}, ${order.PaidAmount ? order.PaidAmount : 0}\n`;
        });
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'orders-report.csv';
        hiddenElement.click();
    }



    return (
        <div className='w-[95%] mx-auto h-full'>
            <h1 className='text-3xl text-gray-700 font-bold'>Course Manager</h1>
            <section className="my-5 antialiased">
                <div className="mx-auto">
                    {isLoading ? (
                        "Loading..."
                    ) : (
                        <div className="bg-white shadow-md sm:rounded-lg">
                            <div className='flex flex-row flex-wrap justify-between items-center space-x-4 p-4'>

                                <div className='flex flex-row justify-start flex-wrap items-center space-x-4 p-4'>
                                <div className={`relative`}>
                                        <label className="mb-[0.4rem] block text-black"> Courses </label>
                                        <Select
                                            mode="multiple"
                                            placeholder="Select Courses"
                                            onChange={(value) => {
                                                setForm({ ...form, courses: value })
                                            }}
                                            style={{ width: 200}}
                                            value={form.courses || []}
                                        >
                                            {coursesData.map((course) => (
                                                <Select.Option key={course._id} value={course._id}>{course.courseName}</Select.Option>
                                            ))}
                                        </Select>
                                    </div>



                                    <CustomDropDown
                                        label="Subscription Status"
                                        options={["All", "Active", "Turned Off" , "Pending", "Expired"]}
                                        onSelect={(selectedStatus) =>
                                            setForm({ ...form, status: selectedStatus.toString() })
                                        }
                                        width="min-w-[16rem]"
                                        value={form.status}
                                    />

                                    <div className={`relative`}>
                                        <label className="mb-[0.4rem] block text-black"> Start Date</label>
                                        <input
                                            type="date"
                                            value={form.startDate}
                                            onChange={(e) => { setForm({ ...form, startDate: e.target.value }) }} // Use e.target.value to get the date string
                                            className="w-full w-54 h-[3.2rem] border border-gray-300 p-2"
                                        />
                                    </div>

                                    <div className={`relative`}>
                                        <label className="mb-[0.4rem] block text-black"> End Date</label>
                                        <input
                                            type="date"
                                            value={form.endDate}
                                            onChange={(e) => { setForm({ ...form, endDate: e.target.value }) }} // Use e.target.value to get the date string
                                            className="w-full w-54 h-[3.2rem] border border-gray-300 p-2"
                                        />

                                    </div>


                                    <CustomDropDown
                                        label="Enrollment Type"
                                        options={["With Bundles" , "Manually", "Course OMS"]}
                                        onSelect={(selectedStatus) =>
                                            setForm({ ...form, type: selectedStatus.toString() })
                                        }
                                        width="min-w-[16rem]"
                                        value={form.type}
                                    />





                                    <div className={`relative`}>
                                        <label className="mb-[0.4rem] block text-black"> Coupons</label>
                                        <Select
                                            mode="multiple"
                                            placeholder="Select Coupons"
                                            onChange={(value) => {
                                                setForm({ ...form, coupons: value })
                                            }}
                                            style={{ width: 200 , height: 50 }}
                                            value={form.coupons || []}
                                        >
                                            {coupons.map((coupon, index) => (
                                                <Select.Option key={index} value={coupon.CouponCode}>{coupon.CouponCode}</Select.Option>
                                            ))}
                                        </Select>
                                    </div>


                                </div>


                                <div className='flex flex-row justify-end items-center space-x-4 p-4'>
                                    <ButtonFill
                                        handleClick={DownloadCSV}
                                        isLoading={isLoading}
                                    >
                                        Download CSV
                                    </ButtonFill>

                                    <ButtonFill
                                        handleClick={fetchOrderDetails}
                                        isLoading={isLoading}
                                    >
                                        Search
                                    </ButtonFill>
                                </div>

                            </div>


                            <div className="overflow-x-auto">

                                <div className="flex flex-col md:flex-row justify-between items-center p-4 gap-4">
                                    <h1 className="h-24 w-full shadow-md p-4 bg-white rounded-lg flex flex-col justify-center items-center text-lg text-gray-700 font-bold">Total Orders: {orders.length}</h1>
                                    <h1 className="h-24 w-full shadow-md p-4 bg-white rounded-lg flex flex-col justify-center items-center text-lg text-gray-700 font-bold">Total Paid Amount: {totalPaidAmount.toFixed()}</h1>
                                    <h1 className="h-24 w-full shadow-md p-4 bg-white rounded-lg flex flex-col justify-center items-center text-lg text-gray-700 font-bold">Total Discount Amount: {totalDiscountAmount}</h1>
                                    <h1 className="h-24 w-full shadow-md p-4 bg-white rounded-lg flex flex-col justify-center items-center text-lg text-gray-700 font-bold">Average Order Value: {averageOrderValue.toFixed()} </h1>
                                </div>

                                <div className="flex flex-row flex-wrap justify-start gap-2 items-center p-4">
                                    <ButtonFill
                                        handleClick={GenerateMonthlyReport}
                                    >
                                        Generate Monthly Report
                                    </ButtonFill>
                                    <ButtonFill
                                        handleClick={GenerateYearlyReport}
                                    >
                                        Generate Yearly Report
                                    </ButtonFill>
                                </div>

                                <div className="flex flex-col md:flex-row justify-start items-center p-4">
                                    {monthlyBarData.length > 0 && (
                                        <div className="flex flex-col items-center justify-between p-4 space-y-3">

                                            <BarChart
                                                width={600}
                                                height={300}
                                                data={monthlyBarData}
                                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                            >
                                                <XAxis dataKey="month" />
                                                <YAxis />
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="months" fill="#DA9F21" />
                                            </BarChart>
                                            <ButtonFill
                                                handleClick={DownloadMonthlyReport}
                                            >
                                                Download Report
                                            </ButtonFill>
                                        </div>
                                    )}

                                    {yearlyBarData.length > 0 && (
                                        <div className="flex flex-col items-center justify-between p-4 space-y-3">
                                            <BarChart
                                                width={600}
                                                height={300}
                                                data={yearlyBarData}
                                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                            >
                                                <XAxis dataKey="year" />
                                                <YAxis />
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="years" fill="#3AF581" />
                                            </BarChart>
                                            <ButtonFill
                                                handleClick={DownloadYearlyReport}
                                            >
                                                Download Report
                                            </ButtonFill>
                                        </div>
                                    )}
                                </div>



                                <table className="w-full mb-20 text-sm text-left text-gray-500">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-4 py-4">
                                                Date of Order
                                            </th>

                                            <th scope="col" className="px-4 py-4">
                                                Time of Order
                                            </th>
                                            <th scope="col" className="px-4 py-4 text-left">
                                                Customer Name
                                            </th>
                                            <th scope="col" className="px-4 py-4 text-left">
                                                Coupon Used
                                            </th>
                                            <th scope="col" className="px-4 py-4 text-center">
                                                Products
                                            </th>
                                            <th scope="col" className="px-4 py-3 text-center">
                                                Order Status
                                            </th>
                                            <th scope="col" className="px-4 py-3 text-center">
                                                Subscription
                                            </th>
                                            <th scope="col" className="px-4 py-3 text-center">
                                                Subscription Status
                                            </th>                                        
                                            <th scope="col" className="px-4 py-3 text-center">
                                                Total Amount
                                            </th>
                                            <th scope="col" className="px-4 py-3 text-center">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders.map((order) => (
                                            <tr className="border-b" key={order._id}>
                                                <td className="px-4 py-3">{GetDate(order.CreationDate)}</td>
                                                <td className="px-4 py-3">{GetTime(order.CreationDate)}</td>
                                                <td className="px-4 py-3">{order.UserName}</td>
                                                <td className="px-4 py-3">{order.CouponCode}</td>
                                                <td className="px-4 py-3 text-center">{getPlanName(order.plans)}</td>
                                                <td className={`px-4 py-3 text-center`} >
                                                    {order.OrderStatus === "Pending" && (<CustomBadges label="Pending" type="warning" />)}
                                                    {order.OrderStatus === "Accepted" && (<CustomBadges label="Active" type="success" />)}
                                                    {order.OrderStatus === "Denied" && (<CustomBadges label="Expired" type="light" />)}
                                                </td>
                                                <td className="px-4 py-3 text-center">{GetSubscriptions(order)?.SubscriptionName}</td>
                                                <td className={`px-4 py-3 text-center`} >{GetSubscriptions(order)?.SubscriptionStatus}</td>
                                                <td className="px-4 py-3 text-center">{order.PaidAmount ? order.PaidAmount : 0}</td>
                                                <td className="px-4 py-3 text-center">
                                                    <button
                                                        onClick={() => {
                                                            window.location.href = `/verifyorder/${order._id}`;
                                                        }}
                                                    >
                                                        <EditIcon size="w-6 h-6" />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </div>
    )
}

