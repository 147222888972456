import React from 'react';
import { Image, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';

// Define the structure of the data
interface PaymentRecord {
  _id: string;
  type: string;
  serviceProvider: string | null;
  employee: string;
  amount: number;
  accountNumber: string;
  bankName: string;
  addedBy: string;
  createdDate: string;
  createdAt: string;
  updatedAt: string;
  screenshotUrl: string;
}

// Define columns for the table
const columns: ColumnsType<PaymentRecord> = [
 
 
  {
    title: 'Amount (PKR)',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Account Number',
    dataIndex: 'accountNumber',
    key: 'accountNumber',
  },
  {
    title: 'Bank Name',
    dataIndex: 'bankName',
    key: 'bankName',
  },
  {
    title: 'Added By',
    dataIndex: 'addedBy',
    key: 'addedBy',
  },
  {
    title: 'Created Date',
    dataIndex: 'createdDate',
    key: 'createdDate',
    render: (date: string) =>   date&& dayjs(date)?.format('DD/MM/YYYY, hh:mm a'), 
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
      render: (date: string) => date&&  dayjs(date)?.format('DD/MM/YYYY, hh:mm a'), 
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
      render: (date: string) =>  date&&   dayjs(date)?.format('DD/MM/YYYY, hh:mm a'), 
  },
  {
    title: 'Screenshot',
    dataIndex: 'screenshotUrl',
    key: 'screenshotUrl',
    render: (date: string) => date ?<Image  src={date} width={50} height={50}/>: 'N/A', // Provide a link to the screenshot
  },
];

// Sample data for the table


const PaymentTable: React.FC<{data:any}> = ({data}) => (
  <Table
    dataSource={data}
    columns={columns}
    pagination={false}
    size='small'
    rowKey="_id" // Unique key for each row
  />
);

export default PaymentTable;
