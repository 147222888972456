import { BankDetailsAPI } from '../../(apis)/(shared-apis)/bank-details';
import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import Table from './components/history-table-view';
import { customAPIHandler } from '(apis)/api-interface/api-handler';

export default function Page() {
    const { category , id } = useParams();
    const [historyDetails, setHistoryDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const GetDetails = async () => {

        setLoading(true);
        try {
            const response = await customAPIHandler("getHistoryById", id, null, category); 
            setHistoryDetails(response.data.History);
        }
        catch (error) {
            console.error("Error getting bank details data:", error);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        GetDetails();
    }, [])



    return (
        <div className="w-[95%] mx-auto pb-20 px-5">
            <div className="flex items-center justify-between pb-4 mt-5">
                <h2 className="text-2xl font-bold text-gray-700">Bank History</h2>
            </div>

            {loading ?
                <div className='h-96 flex items-center justify-center'>
                    <div className='text-2xl font-bold text-gray-700'>Loading...</div>
                </div>
                : <Table data={historyDetails} />}

            <Toaster />
        </div>
    )
}