import React from "react";
import GlobalTable from "shared-components/table/GlobalTable";
import { EditIcon, EyeIcon } from "shared-components/ui/Icons";

const EmployeeTable = ({
  handleEdit,
  isLoading,
  EmployeeData,
  setViewModal,
  setCurrentEmployeeData,
}) => {
  const columns = [
    {
      title: " Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Salary (PKR)",
      dataIndex: "salary",
      key: "salary",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    // {
    //   title: "Departments",
    //   dataIndex: "departments",
    //   key: "departments",
    // },
    {
      title: "Positions",
      dataIndex: "positions",
      key: "positions",
    },
    {
      title: "PhoneNumber",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Added By",
      dataIndex: "addedBy",
      key: "addedBy",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-4">
          <div
            className="text-xl flex gap-4"
            onClick={() => handleEdit(record)}
          >
            <EditIcon color="text-gray-800" size="w-6 h-6"/>
          </div>
          <div
            className="text-xl flex gap-4"
            onClick={() => {
              setCurrentEmployeeData(record);
              setViewModal(true);
            }}
          >
            <EyeIcon color="text-gray-800" size="w-6 h-6"/>
          </div>
        </div>
      ),
    },
  ];
  return (
    <div>
      <GlobalTable loading={isLoading} columns={columns} data={EmployeeData}             size="large"
 />
    </div>
  );
};

export default EmployeeTable;
