import React from "react";
import { EditIcon } from "../../../shared-components/ui/Icons";

export default function Table({ data }) {
  const options = { day: "numeric", month: "long", year: "numeric" };
  return (
    <div className="bg-white shadow-md sm:rounded-lg">
      <div className="overflow-x-auto">
        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4">
                OrderID
              </th>
              <th scope="col" className="px-4 py-4">
                Submitted By
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Notes
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Amount
              </th>
              <th scope="col" className="px-4 py-3 text-center">
                Time
              </th>
              <th scope="col" className="px-4 py-4 text-center">
                Payment Image
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((historyDetail) => (
              <tr className="border-b" key={historyDetail._id}>
                <td className="px-4 py-3">
                  {historyDetail.OrderId || "NOT SPECIFIED"}
                </td>
                <td className="px-4 py-3">
                  {historyDetail.SubmittedBy || "NOT SPECIFIED"}
                </td>
                <td className="px-4 py-3">
                  {historyDetail.Notes || "NOT SPECIFIED"}
                </td>
                <td className="px-4 py-3">
                  {historyDetail.Amount || "NOT SPECIFIED"}
                </td>

                <td className="px-4 py-3 text-center">
                  {new Date(historyDetail.createdAt).toLocaleDateString('en-GB')}
                </td>
                <td className="px-4 py-3 text-center">
                  <button
                    className="text-blue-500"
                    onClick={() => {
                      window.open(historyDetail.PaymentImage, "_target");
                    }}
                  >
                    <EditIcon size="w-6 h-6" color="text-rose-500" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
