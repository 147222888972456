import CustomBadges from '../../../shared-components/ui/CustomBadges';
import { Switcher } from '../../../shared-components/ui/CustomForm';
import { EditIcon, ManageIcon } from '../../../shared-components/ui/Icons';

export default function Table ({ data , setDetails, setModalType, setModuleModal  , UpdateStatus , category }){

    return (
        <div className="bg-white shadow-md sm:rounded-lg">
            <div className="overflow-x-auto">
                <table className="w-full mb-20 text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" className="px-4 py-4">
                                Type
                            </th>
                            <th scope="col" className="px-4 py-4">
                                Bank Name
                            </th>
                            <th scope="col" className="px-4 py-4 text-left">
                                Account Title
                            </th>
                            <th scope="col" className="px-4 py-4 text-left">
                                Account Number
                            </th>
                            <th scope="col" className="px-4 py-4 text-center">
                                IBAN
                            </th>
                            <th scope="col" className="px-4 py-3 text-center">
                                Amount Deposited
                            </th>
                            <th scope="col" className="px-4 py-3 text-center">
                                Orders Associated
                            </th>
                            <th scope="col" className="px-4 py-3 text-center">
                                Status
                            </th>
                            <th scope="col" className="px-4 py-3 text-center">
                                Position
                            </th>
                            <th scope="col" className="px-4 py-3 text-center">
                                History
                            </th>
                            <th scope="col" className="px-4 py-3 text-center">
                                Edit
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((bankDetail) => (
                            <tr className="border-b" key={bankDetail._id}>

                                <td className="px-4 py-3">
                                    {bankDetail.Type === "BANK TRANSFER" && (<CustomBadges label={bankDetail.Type} type="success" />)}
                                    {bankDetail.Type === "EASYPAISA" && (<CustomBadges label={bankDetail.Type} type="warning" />)}
                                    {bankDetail.Type === "JAZZCASH" && (<CustomBadges label={bankDetail.Type} type="info" />)}
                                </td>
                                <td className="px-4 py-3">{bankDetail.BankName || 'NOT SPECIFIED'}</td>
                                <td className="px-4 py-3">{
                                    bankDetail.AccountTitle || 'NOT SPECIFIED'
                                }</td>
                                <td className="px-4 py-3">{bankDetail.AccountNumber || 'NOT SPECIFIED'}</td>
                                <td className="px-4 py-3 text-center">{bankDetail.IBAN || 'NOT SPECIFIED'}</td>
                                <td className="px-4 py-3 text-center">{bankDetail.AmountDeposited || 0}</td>
                                <td className="px-4 py-3 text-center">{bankDetail.OrdersAssociated.length || 0}</td>
                                <td className="px-4 py-3">
                                    <div className="mx-auto w-fit">
                                        <Switcher
                                            isActive={undefined}
                                            for={bankDetail._id}
                                            onChange={() => {
                                                UpdateStatus(bankDetail._id, bankDetail.isPublished);
                                            }}
                                            togglevalue={bankDetail.isPublished}
                                            size="small"
                                        />
                                    </div>
                                </td>

                                <td className="px-4 py-3 text-center">{bankDetail.Position || 0}</td>
                                <td className="px-4 py-3 text-center">
                                    <button className="text-blue-500"
                                        onClick={() => {
                                            window.open(`/manage/history/${category}/${bankDetail._id}`, "_target");
                                        }}
                                    >
                                        <ManageIcon size="w-6 h-6" color="text-rose-500" />
                                    </button>
                                </td>

                                <td className="px-4 py-3 text-center">
                                    <button className="text-blue-500"
                                        onClick={() => {
                                            setDetails(bankDetail);
                                            setModalType('edit');
                                            setModuleModal(true);
                                        }}
                                    >
                                        <EditIcon size="w-6 h-6" color="text-rose-500" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
   
    )
}