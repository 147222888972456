import CustomFormField from "shared-components/form-fields/custom-form-field";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";

const PaymentModal = ({
  openModal,
  isEditMode,
  setOpenModal,
  formik,
  employeeData,
  serviceProviderData,
}) => {
  return (
    <CustomAntdModal
      isOpenModal={openModal}
      title={isEditMode ? `Edit Payment` : `Add Payment`}
      setIsOpenModal={setOpenModal}
      formik={formik}
    >
      <form onSubmit={formik.handleSubmit}>
        {/* Type Selector (Internal/External) */}
        <CustomFormField
          type="select"
          name="type"
          label="Payment Type"
          disabled={isEditMode}
          formik={formik}
          options={[
            { label: "Internal", value: "Internal" },
            { label: "External", value: "External" },
          ]}
          require={true}
        />

        {/* External case fields */}
        {formik.values.type === "External" && (
          <>
            <CustomFormField
              type="select"
              name="serviceProvider"
              label="Service Provider"
              formik={formik}
              disabled={isEditMode}
              options={serviceProviderData}
              require={true}
              isSetValue={true}
            />

            <CustomFormField
              type="text"
              name="bankName"
              label="Bank Name"
              formik={formik}
              require={true}
            />
          </>
        )}

        {/* Internal case fields */}
        {formik.values.type === "Internal" && (
          <>
            <CustomFormField
              type="select"
              name="employee"
              label="Employee"
              formik={formik}
              disabled={isEditMode}
              isSetValue={true}
              options={employeeData}
              require={true}
              
            />
            <CustomFormField
              type="select"
              name="bankName"
              label="Employee Bank"
              formik={formik}
              options={employeeData
                ?.filter(
                  (employee) => employee._id === formik.values.employee
                )?.[0]
                ?.bankDetails?.map((bank) => {
                  return { label: bank.bankName, value: bank.bankName };
                })}
              require={true}
            />
          </>
        )}
        <CustomFormField
          type="text"
          name="accountNumber"
          label="Account Number"
          formik={formik}
          require={true}
          // disabled={true}
        />
        {/* Common fields */}
        <CustomFormField
          type="number"
          name="amount"
          label="Amount"
          formik={formik}
          require={true}
          prefix="PKR"
        />

        <CustomFormField
          type="image"
          name="screenshot"
          label="Upload Screenshot"
          formik={formik}
          isMultipleImage={false}
          require={false} // Optional field
        />
      </form>
    </CustomAntdModal>
  );
};

export default PaymentModal;
