import { Divider } from "antd";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import BankDetailsForm from "./bank-details-Form";

const EmployeeModal = ({
  addPopup,
  isEditMode,
  setAddPopup,
  formik,
  departmentData,
}) => {
  return (
    <div>
      <CustomAntdModal
        isOpenModal={addPopup}
        title={isEditMode ? `Edit Employee` : `Add Employee `}
        setIsOpenModal={setAddPopup}
        formik={formik}
        //   width={800}
      >
        <div className="flex flex-col  gap-2">
          <div className="flex gap-3">
            <div className="w-full md:w-1/3">
              <CustomFormField
                type="text"
                name="name"
                formik={formik}
                label=" Name"
              />
            </div>
            <div className="w-full md:w-1/3">
              <CustomFormField
                type="text"
                name="email"
                formik={formik}
                label="Email"
              />
            </div>
            <div className="w-full md:w-1/3">
              <CustomFormField
                type="number"
                name="phoneNumber"
                formik={formik}
                label="PhoneNumber"
              />
            </div>
            <div className="w-full md:w-1/3">
              <CustomFormField
                type="number"
                name="salary"
                formik={formik}
                label="Salary"
              />
            </div>
          </div>
          <div className="flex gap-3">
            <div className="w-full md:w-1/3 ">
              <CustomFormField
                type="select"
                name="type"
                formik={formik}
                label="Type"
                mode="multiple"
                options={[
                  {
                    label: "Monthly",
                    value: "Monthly",
                  },
                  {
                    label: "Per Task",
                    value: "Per Task",
                  },
                  {
                    label: "Commission",
                    value: "Commission",
                  },
                  {
                    label: "Unpaid",
                    value: "Unpaid",
                  },
                ]}
              />
            </div>
            <div className="w-full md:w-1/3 ">
              <CustomFormField
                type="select"
                name="departments"
                formik={formik}
                label="Department (s)"
                mode="multiple"
                // isSetValue={true}
                options={departmentData}
              />
            </div>
            <div className="w-full md:w-1/3 ">
              <CustomFormField
                type="select"
                name="positions"
                formik={formik}
                label="Position (s)"
                mode="tags"
              />
            </div>
          </div>

          {/* Bank Details FormArray */}
          <Divider>Employee Bank Details</Divider>
          <BankDetailsForm formik={formik} />

        </div>
      </CustomAntdModal>
    </div>
  );
};

export default EmployeeModal;
