import { customAPIHandler } from "(apis)/api-interface/api-handler";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  ButtonFill,
  ButtonOutlined,
} from "../../../shared-components/ui/CustomButtons";
import CustomDropDown from "../../../shared-components/ui/CustomDropDown";
import { Input, Label } from "../../../shared-components/ui/CustomForm";
import { ConfirmationModal } from "../../../shared-components/ui/CustomModal";
import { BankDetailsModal } from "../interface";


export const CreateModule = (props) => {

  const InitialErrors = {
    typeError: {
      error: false,
      message: "",
    },
    bankNameError: {
      error: false,
      message: "",
    },
    accountTitleError: {
      error: false,
      message: "",
    },
    accountNumberError: {
      error: false,
      message: "",
    },

  };
  const [errors, setErrors] = useState(InitialErrors);

  const initialState = {
    type: "",
    bankName: "",
    accountTitle: "",
    accountNumber: "",
    IBAN: "",
    amountDeposited: 0,
    ordersAssociated: [],
    isPublished: false,
    position: 1000,
  };

  const [formData, setFormData] = useState<BankDetailsModal>(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    const newErrors = { ...errors };
    let isError = false;
    if (!formData.type) {
      newErrors.typeError.error = true;
      newErrors.typeError.message = "Type is required";
      isError = true;
    }
    else {
      newErrors.typeError.error = false;
      newErrors.typeError.message = "";
    }

    if (formData.type === "BANK TRANSFER" && !formData.bankName) {
      newErrors.bankNameError.error = true;
      newErrors.bankNameError.message = "Bank Name is required";
      isError = true;
    }
    else {
      newErrors.bankNameError.error = false;
      newErrors.bankNameError.message = "";
    }

    if (!formData.accountTitle) {
      newErrors.accountTitleError.error = true;
      newErrors.accountTitleError.message = "Account Title is required";
      isError = true;
    }
    else {
      newErrors.accountTitleError.error = false;
      newErrors.accountTitleError.message = "";
    }

    if (!formData.accountNumber) {
      newErrors.accountNumberError.error = true;
      newErrors.accountNumberError.message = "Account Number is required";
      isError = true;
    }
    else {
      newErrors.accountNumberError.error = false;
      newErrors.accountNumberError.message = "";
    }

    setErrors(newErrors);
    return isError;
  };

  useEffect(() => {
    if (props.mode === "edit") {
      setFormData({
        _id: props.details._id,
        type: props.details.Type,
        bankName: props.details.BankName,
        accountTitle: props.details.AccountTitle,
        accountNumber: props.details.AccountNumber,
        IBAN: props.details.IBAN,
        amountDeposited: props.details.AmountDeposited,
        ordersAssociated: props.details.OrdersAssociated,
        isPublished: props.details.isPublished,
        position: props.details.Position,
      });
    }
    else {
      setFormData(initialState);
    }
  }, [props.mode , props.details]);

  const handleInputChange = (name: string, value: string | number | boolean) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const resetAll = () => {
    setFormData(initialState);
    props.setModal(false);
    setConfirmationModal(false);
  };

  const Submit = async () => {
    if (validateForm()) {
      return;
    }
    setDisableCreateButton(true);

    try {
      let response = null;
      if (props.mode === "edit") {
        response = await customAPIHandler("updateBankDetails", formData._id, formData, props.category);
      } else {
        response = await customAPIHandler("addBankDetails", "", formData, props.category);
      }
      if (response.data.success) {
        props?.mode === "edit"
          ? toast.success("Updated Successfully 🚀")
          : toast.success("Added Successfully 🚀");
        props.GetDetails();
        resetAll();
      }
      else {
        toast.error(response.data.message);
      }

    } catch (error) {
      console.log("Add Module Error: ", error);
      toast.error("Error Adding Bank Account");
    } finally {
      setIsLoading(false);
    }
    setDisableCreateButton(false);
  };

  const [confirmationModal, setConfirmationModal] = useState(false);

  return (
    <div
      id="createProductModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${props.active ? "" : "hidden"
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen backdrop-blur-sm !space-x-0`}
    >
      <div className="relative w-full max-w-2xl max-h-full p-4">
        <div className="relative p-4 bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between pb-4 mb-4 border-b rounded-t sm:mb-5">
            <h3 className="text-lg font-semibold text-gray-900 ">
              {props.mode === "add" ? "Add New Bank Account" : "Edit Bank Account"}
            </h3>
            {/* <MyToast /> */}
            <button
              type="button"
              className="text-gray-400 bg-transparent hover.bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
              data-modal-target="createProductModal"
              data-modal-toggle="createProductModal"
              onClick={() => {
                props.setModal(false);
              }}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="space-y-2">
            <div className="flex items-center space-x-3 w-full ">
              <CustomDropDown
                label="Select Type"
                options={["BANK TRANSFER", "EASYPAISA", "JAZZCASH"]}
                onSelect={(value) =>
                  setFormData({
                    ...formData,
                    type: value,
                  })
                }
                width="w-full"
                value={formData.type}
                isError={errors.typeError.error}
                errorMessage={errors.typeError.message}
              />
            </div>

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              {formData.type === "BANK TRANSFER" && (
              <div className="space-y-2">
                <Label>Bank Name</Label>
                <Input
                  type="text"
                  placeholder="Enter Bank Name"
                  value={formData.bankName}
                  name="bankName"
                  onChange={handleInputChange}
                  isError={errors.bankNameError.error}
                  errorMessage={errors.bankNameError.message}                  
                />
              </div>
              )}

              <div className="space-y-2">
                <Label>Account Title</Label>
                <Input
                  type="text"
                  placeholder="Enter Account Title"
                  value={formData.accountTitle}
                  name="accountTitle"
                  onChange={handleInputChange}
                  isError={errors.accountTitleError.error}
                  errorMessage={errors.accountTitleError.message}
                />
              </div>

              <div className="space-y-2">
                <Label>Account Number</Label>
                <Input
                  type="text"
                  placeholder="Enter Account Number"
                  value={formData.accountNumber}
                  name="accountNumber"
                  onChange={handleInputChange}
                  isError={errors.accountNumberError.error}
                  errorMessage={errors.accountNumberError.message}
                />
              </div>

              <div className="space-y-2">
                <Label>IBAN</Label>
                <Input
                  type="text"
                  placeholder="Enter IBAN"
                  value={formData.IBAN}
                  name="IBAN"
                  onChange={handleInputChange}
                />
              </div>

              <div className="space-y-2">
                <Label>Position</Label>
                <Input
                  type="number"
                  placeholder="Enter Position"
                  value={formData.position as any}
                  name="position"
                  onChange={handleInputChange}
                />
              </div>
            </div>



            <div className="flex items-center justify-end gap-2 items-center">
              <ButtonOutlined
                disabled={isLoading}
                isLoading={isLoading}
                handleClick={() => {
                  if (
                    props.mode === "edit" &&
                    JSON.stringify(formData) !== JSON.stringify(props.details)
                  ) {
                    setConfirmationModal(true);
                  } else if (
                    formData.type !== "" ||
                    formData.accountTitle !== "" ||
                    formData.accountNumber !== ""                    
                  ) {
                    setConfirmationModal(true);
                  } else {
                    props.setModal(false);
                  }
                }}
              >
                Cancel
              </ButtonOutlined>
              <ButtonFill
                isLoading={isLoading}
                handleClick={Submit}
                disabled={disableCreateButton}
              >
                {props.mode === "add" ? "Create" : "Update"}
              </ButtonFill>
            </div>
          </div>
          <ConfirmationModal
            active={confirmationModal}
            onCancel={() => {
              setConfirmationModal(false);
            }}
            onConfirm={() => {
              setFormData(initialState);
              props.setModal(false);
              setConfirmationModal(false);
            }}
            message={"Are you sure you want to leave this page?"}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateModule;
