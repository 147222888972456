import { Categories } from 'constants/constantvalues'
import React from 'react'
import CustomDropDown from 'shared-components/ui/CustomDropDown'

export default function CategoryDropdown({ selectedCategory, setSelectedCategory , noLabel=false }) {
    return (
        <CustomDropDown
            label="Category"
            options={Categories}
            onSelect={(selectedCategory) =>
                setSelectedCategory(selectedCategory.toString())
            }
            width="min-w-[16rem]"
            value={selectedCategory}
            noLabel={noLabel}
        />

    )
}
