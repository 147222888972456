import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Image, Popconfirm } from "antd";
import dayjs from "dayjs";
import GlobalTable from "shared-components/table/GlobalTable";
import { EditIcon } from "shared-components/ui/Icons";
import { TypeList } from "./functions";

const PaymentTable = ({ isLoading, paymentData,handleEdit,handleDelete }) => {
  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Employee",
      dataIndex: "employeeName",
      key: "employeeName",
      render: (employeeName) => (employeeName ? employeeName : "N/A"),
    },
    {
      title: "Service Provider",
      dataIndex: "serviceProviderName",
      key: "serviceProviderName",
      render: (serviceProviderName) =>
        serviceProviderName ? serviceProviderName : "N/A",
    },
 
    {
      title: "Amount (PKR)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Account Number",
      dataIndex: 'accountNumber',
      key: "accountNumber",
      render: (accountNumber) => (accountNumber ? accountNumber : "N/A"),
    },
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
      render: (bankName) => (bankName ? bankName : "N/A"),
    },

    {
      title: "Screenshot",
      dataIndex: "screenshotUrl",
      key: "screenshotUrl",
      render: (screenshotUrl) =>
        screenshotUrl ? (
         <Image  src={screenshotUrl} width={40} height={40} style={{ borderRadius: "100%"}}/>
        ) : (
          "No Screenshot"
        ),
    },
    {
      title: "Created By",
      dataIndex: "addedBy",
      key: "addedBy",
    },
    {
      title: "Updated By",
      dataIndex: "addedBy",
      key: "addedBy",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date) => {
        return dayjs(date).format("DD/MM/YYYY");
      },
    },
    {
      title: "Updated Date",
      dataIndex: "updatedDate",
      key: "updatedDate",
      render: (date) => {
        return dayjs(date).format("DD/MM/YYYY");
      }, // Format the date
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex  gap-4">
          <div className="text-xl flex gap-4"  onClick={() => handleEdit(record)}>
          <EditIcon color="text-gray-800" size="w-6 h-6" />
          </div>
          <Popconfirm
              title="Delete the Payment Record"
              description="Are you sure to delete this Payment Record?"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() =>handleDelete(record?._id)}
            >
             <DeleteOutlined className="cursor-pointer w-6 h-6"/>
            </Popconfirm>
        </div>
      ),
    },
  ];
  const formatedData = paymentData?.map((payment) => {
    return {
      key: payment.id,
      ...payment,
      statusValue: payment.type,
    };
  });
  return (
    <div>
      <GlobalTable
        loading={isLoading}
        columns={columns}
        statusList={TypeList}
        data={formatedData}
      />
    </div>
  );
};

export default PaymentTable;
