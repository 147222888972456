export const TypeList = [
  {
    key: -1,
    label: "All",
  },
  {
    key: "Internal",
    label: "Internal",
  },
  {
    key: "External",
    label: "External",
  },
];
