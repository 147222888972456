import React from "react";
import { Input, message, Modal } from "antd";
import { OrderAPI } from "../../../(apis)/(shared-apis)/order-apis";
import toast from "react-hot-toast";
import { customAPIHandler } from "(apis)/api-interface/api-handler";
// import { updateCouponCode, GetOrder } from "../../../(apis)/(shared-apis)/order-apis";

const UpdateCouponCodeModal = ({
  isModalOpen,
  setIsModalOpen,
  orderid,
  SetOrder,
  entity
}) => {
  const [couponCode, setCouponCode] = React.useState("");
  const [isUpdatingCouponCode, setIsUpdatingCouponCode] = React.useState(false);
  const handleOk = async () => {
    try {
      setIsUpdatingCouponCode(true);
      const trimedCouponCode = couponCode.trim()?.toUpperCase();
      const response = await customAPIHandler("updateCouponCode", orderid, { couponCode: trimedCouponCode }, entity);
      if (response.data.isSuccess){
        toast.success(response.data.message)
        setIsModalOpen(false);
      }
      else {
        toast.error("Failed to update coupon code")
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    } finally {
      setIsUpdatingCouponCode(false);
    }
  };
  return (
    <div>
      <Modal
        loading={isUpdatingCouponCode}
        title="Update Coupon Code"
        okText="Update"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={() => setIsModalOpen(false)}
      >
        <Input
          placeholder="Enter Coupon Code"
          onChange={(e) => setCouponCode(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default UpdateCouponCodeModal;