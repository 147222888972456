import { track } from "fbq";
import { useContext, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
// import { fetchPlanSubscriptionName, GetOrder, OrderAPI } from '../../(apis)/(shared-apis)/order-apis';
import CustomBadges from '../../shared-components/ui/CustomBadges';
import { CopyIcon } from '../../shared-components/ui/Icons';
import { UserContext } from '../../state-management/user-context';
import AddToBankModal from './components/add-to-bank-modal';
import DeleteOrderModal from './components/delete-order-modal';
import DenyOrderModal from './components/deny-order-modal';
import TableView from "./components/order-table-view";
import { TableSecondaryView } from './components/table-secondary-view';
import UpdateCouponCodeModal from './components/update-couponcode-modal';
import { OrderInterface } from './interfaces/order-interfaces';
import { OrderDefaultValues } from './utils';
import { OrderAPI } from "../../(apis)/(shared-apis)/order-apis";
import { ButtonFill } from "../../shared-components/ui/CustomButtons";
import { customAPIHandler } from "(apis)/api-interface/api-handler";
import OrderHistory from "shared-pages/user-management/components/orders";


export default function VerifyScreen() {

  let { entity, orderid } = useParams();
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("Layout must be used within a UserProvider");
  }
  const { user } = context;


  const [order, SetOrder] = useState<OrderInterface>(OrderDefaultValues)
  const [isUpdateCouponModalOpen, setIsUpdateCouponModalOpen] = useState(false);
  const [planSubscriptionNames, setPlanSubscriptionNames] = useState("");
  const [addAmountToBankModal, SetBankModal] = useState(false);
  const [openDeleteModal, SetopenDeleteModal] = useState(false);
  const [denyModal, SetDenyModal] = useState(false);


  useEffect(() => {
    customAPIHandler("getOrder", orderid, null, entity).then((res: { data: (prevState: undefined) => undefined; }) => [SetOrder(res.data)]);
  }, []);


  const AcceptOrder = async (status: string) => {
    if (status === "Accepted") {
      await customAPIHandler("changeStatusForBundlePurchase", "", {
        orderId: order._id,
        newStatus: "Accepted",
        message: "Order Accepted"
      }, entity).then(() => {
        toast.success(`Order ${order._id} Accepted`);
        track("Purchase", {
          value: order.PaidAmount,
          currency: "PKR",
        });
        SetBankModal(true)
        OrderAPI("getOrder", orderid).then((res: { data: (prevState: undefined) => undefined; }) => [SetOrder(res.data)]);
      });
    }
  }

  return (
    <div className="relative">
      <div className="w-full md:w-[95%] mx-auto h-full">
        <h1 className="text-3xl text-gray-700 font-bold">Order Manager</h1>
        <Toaster />
        <div className="bg-white shadow-md sm:rounded-lg p-4">
          <div className="flex justify-start items-center">
            <div className="flex items-center gap-2 justify-start cursor-pointer">
              <h1 className="text-md font-semibold text-gray-800">
                Order ID: {order._id}
              </h1>
              <div
                className="hover:bg-gray-200 p-2 rounded-lg"
                onClick={() => {
                  navigator.clipboard.writeText(order._id);
                  toast.success("Order ID Copied");
                }}
              >
                <CopyIcon size="w-6 h-6" color="text-gray-500" />
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className={`text-center`}>
              {order.OrderStatus === "Pending" && (
                <CustomBadges
                  label={order.OrderStatus}
                  type="light"
                  textSize="lg"
                />
              )}
              {order.OrderStatus === "Accepted" && (
                <CustomBadges
                  label={order.OrderStatus}
                  type="success"
                  textSize="lg"
                />
              )}
              {order.OrderStatus === "Denied" && (
                <CustomBadges
                  label={order.OrderStatus}
                  type="warning"
                  textSize="lg"
                />
              )}
            </div>

            <div className="flex flex-wrap flex-start gap-2">
              <ButtonFill
                handleClick={() => {
                  SetBankModal(true);
                }}
              >
                Add Amount to Bank
              </ButtonFill>
              <ButtonFill
                handleClick={async () => {
                  AcceptOrder("Accepted");
                }}
              >
                Accept
              </ButtonFill>
              <ButtonFill
                handleClick={() => {
                  SetDenyModal(true);
                }}
                disabled={order.OrderStatus === "Accepted"}
              >
                Deny
              </ButtonFill>
              <ButtonFill
                handleClick={() => {
                  SetopenDeleteModal(true)
                }}
              >
                Delete Order
              </ButtonFill>

              <ButtonFill
                handleClick={() => {
                  setIsUpdateCouponModalOpen(true);
                }}
              >
                Update Coupon Code
              </ButtonFill>
            </div>
          </div>

          <TableView order={order} />
          <TableSecondaryView order={order} />

          {order && order.UserName ?
            <OrderHistory email={order.UserName} fetchUser={true} entity={entity} />
            : null}

        </div>
      </div>




      <AddToBankModal
        active={addAmountToBankModal}
        setModal={SetBankModal}
        onCancel={() => {
          SetBankModal(false);
        }}
        onConfirm={async () => {
          SetBankModal(false);
        }}
        amount={order.PaidAmount}
        orderid={order._id}
        image={order.PaymentProof}
        user={user}
        entity={entity}
      />

      <DeleteOrderModal
        active={openDeleteModal}
        setModal={SetopenDeleteModal}
        onCancel={() => {
          SetopenDeleteModal(false);
        }}
        onConfirm={async () => {
          SetopenDeleteModal(false);
        }}
        orderId={order._id}
        entity={entity}
      />


      <UpdateCouponCodeModal
        isModalOpen={isUpdateCouponModalOpen}
        setIsModalOpen={setIsUpdateCouponModalOpen}
        orderid={order._id}
        SetOrder={SetOrder}
        entity={entity}
      />


      <DenyOrderModal
        active={denyModal}
        setModal={SetDenyModal}
        onCancel={() => {
          SetDenyModal(false);
        }}
        onConfirm={async () => {
          SetDenyModal(false);
        }}
        orderId={order._id}
        entity={entity}
      />



    </div>

  )
}