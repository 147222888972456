import React, { useEffect, useState } from 'react';
import CreateModule from './components/create-modal';
import toast, { Toaster } from 'react-hot-toast';
import { ButtonFill } from '../../shared-components/ui/CustomButtons';
import { BankDetailsAPI } from '../../(apis)/(shared-apis)/bank-details';
// import Loading from '../../Components/ui/Loading';
import Table from './components/table-view';
import { BankInterface } from './interface';
import CategoryDropdown from 'shared-components/table/category-dropdown';
import { customAPIHandler } from '(apis)/api-interface/api-handler';

export default function Page() {

    const [openCreateModuleModal, setModuleModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("PRE-MEDICAL");

    const [bankDetails, setBankDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const GetDetails = async () => {
        setLoading(true);
        try {
            const response = await customAPIHandler("getBankDetails", null, null, selectedCategory);
            setBankDetails(response.data.bankDetails);
        }
        catch (error) {
            setBankDetails([]);
            console.error("Error getting bank details data:", error);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        GetDetails();
    }, [selectedCategory])

    const [details, setDetails] = useState<BankInterface>({
        Type: "",
        BankName: "",
        AccountTitle: "",
        AccountNumber: "",
        IBAN: "",
        AmountDeposited: 0,
        OrdersAssociated: [],
        isPublished: false,
        Position: 1000,
    })

    const [modalType, setModalType] = useState('add');

    const UpdateStatus = async (id: string, isPublished: boolean) => {
        try {
            const response = await customAPIHandler("updatePublishStatus", id, { isPublished: !isPublished }, selectedCategory);
            if (response.data.success) {
                toast.success(`Updated Successfully`);
                setTimeout(() => {
                    GetDetails();
                }, 2000);
            }
            else {
                toast.error(`Error updating bank details status`);
            }
        } catch (error) {
            toast.error(`Error updating bank details status`);
            console.error("Error updating bank details status:", error);
        }
    }


    return (
        <div className="w-[95%] mx-auto pb-20 px-5">
            <div className="flex items-center justify-between pb-4 mt-5">
                <h2 className="text-2xl font-bold text-gray-700">Finance Manager</h2>
                <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                    <CategoryDropdown selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} noLabel={true} />
                    <ButtonFill
                        handleClick={() => {
                            setModalType('add');
                            setDetails({
                                Type: "",
                                BankName: "",
                                AccountTitle: "",
                                AccountNumber: "",
                                IBAN: "",
                                AmountDeposited: 0,
                                OrdersAssociated: [],
                                isPublished: false,
                                Position: 1000,
                            });
                            setModuleModal(true);
                        }}
                    >
                        Add New Bank Account
                    </ButtonFill>
                </div>

            </div>

            {loading ?
                <div className='h-96 flex items-center justify-center'>
                    <div className='text-2xl font-bold text-gray-700'>Loading...</div>
                </div>

                : <Table data={bankDetails} setDetails={setDetails} setModalType={setModalType} setModuleModal={setModuleModal} UpdateStatus={UpdateStatus} category={selectedCategory}/>}

            <CreateModule
                mode={modalType}
                active={openCreateModuleModal}
                setModal={setModuleModal}
                onCancel={() => {
                    setModuleModal(false);
                }}
                onConfirm={() => {
                    setModuleModal(false);
                }}
                details={details}
                GetDetails={GetDetails}
                category={selectedCategory}
            />

            <Toaster />
        </div>
    )
}