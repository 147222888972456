import { Input } from 'antd';
import { FormikProps } from 'formik';
import React from 'react';

interface BankDetail {
  accountNumber: string;
  accountHolderName: string;
  bankName: string;
}


interface BankDetailsFormProps {
  formik: FormikProps<any>; // Possible missing comma?
}

const BankDetailsForm: React.FC<BankDetailsFormProps> = ({ formik }) => {
  const { values, errors, touched, handleSubmit, setFieldValue } = formik;

  const handleAddBankDetail = () => {
    setFieldValue('bankDetails', [
      ...values.bankDetails,
      { accountNumber: '', accountHolderName: '', bankName: '' }, // Possible missing comma?
    ]);
  };

  const handleRemoveBankDetail = (index: number) => {
    const updatedBankDetails = [...values.bankDetails];
    updatedBankDetails.splice(index, 1); // Possible missing comma?
    setFieldValue('bankDetails', updatedBankDetails);
  };

  const handleChange = (index: number, key: keyof BankDetail, value: string) => {
    const updatedBankDetails = [...values.bankDetails];
    updatedBankDetails[index][key] = value; // Possible missing comma?
    setFieldValue('bankDetails', updatedBankDetails);
  };

  return (
    <div className="m-0">
      <form onSubmit={handleSubmit} encType="multipart/form-data" className="mx-4">
        {values.bankDetails.length === 0 && (
          <div className="my-4">No Bank Details Found, please add new.</div>
        )}

        {values.bankDetails.length > 0 &&
          values.bankDetails.map((bankDetail, index) => (
            <div
              key={index}
              className={`overflow-hidden bg-white shadow-md sm:rounded-lg text-semibold p-4 mb-4`}
            >
              <div className="mx-auto p-2 pt-0">
                <div className="flex justify-between my-1 mb-2">
                  <label

                    className="text-md  font-bold  mt-2"
                    htmlFor={`bankDetailAccountNumber${index}`}
                  >
                    Bank Detail {index + 1}
                  </label>
                  {index > 0 && (
                    <button
                      className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500"
                      type="button"
                      onClick={() => handleRemoveBankDetail(index)}
                    >
                      Remove Bank Detail
                    </button>
                  )}
                </div>

                {/* Inputs with potential comma issues */}
                <div>
                  <label
                    htmlFor={`bankDetailAccountNumber${index}`}
                    className="text-md  mt-2"
                  >
                    Account Number:
                  </label>
                  <Input
                    id={`bankDetailAccountNumber${index}`}
                    placeholder="Account Number"
                    value={bankDetail.accountNumber}
                    onChange={(e) => handleChange(index, 'accountNumber', e.target.value)}
                  />
                   
               {typeof errors?.bankDetails?.[index] === "object" &&
                    errors?.bankDetails?.[index]?.accountNumber &&
                    touched?.bankDetails?.[index]?.accountNumber && (
                      <div className="text-red-500 text-xs mt-1">
                        {errors?.bankDetails?.[index]?.accountNumber as any}
                      </div>
                    )}
                </div>

                {/* Additional form fields */}

                <div>
                  <label
                    htmlFor={`bankDetailAccountHolderName${index}`}
                    className="text-md  mt-2"
                  >
                    Account Holder Name:
                  </label>
                  <Input
                    id={`bankDetailAccountHolderName${index}`}
                    placeholder="Account Holder Name"
                    value={bankDetail.accountHolderName}
                    onChange={(e) => handleChange(index, 'accountHolderName', e.target.value)}
                  />
               
               {typeof errors?.bankDetails?.[index] === "object" &&
                    errors?.bankDetails?.[index]?.accountHolderName &&
                    touched?.bankDetails?.[index]?.accountHolderName && (
                      <div className="text-red-500 text-xs mt-1">
                        {errors?.bankDetails?.[index]?.accountHolderName as any}
                      </div>
                    )}
                </div>

                <div>
                  <label
                    htmlFor={`bankDetailBankName${index}`}
                    className="text-md  mt-2"
                  >
                    Bank Name:
                  </label>
                  <Input
                    id={`bankDetailBankName${index}`}
                    placeholder="Bank Name"
                    value={bankDetail.bankName}
                    onChange={(e) => handleChange(index, 'bankName', e.target.value)}
                  />
                
                      {typeof errors?.bankDetails?.[index] === "object" &&
                    errors?.bankDetails?.[index]?.bankName &&
                    touched?.bankDetails?.[index]?.bankName && (
                      <div className="text-red-500 text-xs mt-1">
                        {errors?.bankDetails?.[index]?.bankName as any}
                      </div>
                    )}
                </div>
              </div>
            </div>
          ))}

        <button
          className="bg-rose-500 hover:bg-rose-700 text-white font-bold py-2 px-4 rounded"
          type="button"
          onClick={handleAddBankDetail}
        >
          Add {values.bankDetails.length > 0 ? <span>More</span> : <></>} Bank Details
        </button>

        {errors.bankDetails && typeof errors.bankDetails === 'string' && (
          <div className="text-red-500 text-xs mt-1">{errors.bankDetails as string}</div>
        )}

      
      </form>
    </div>
  );
};

export default BankDetailsForm;
