import { useEffect, useState } from "react";
import { OrderAPI } from "../../(apis)/(shared-apis)/order-apis";
import { TableTop } from "./components/table-view";
import { TableView } from "./components/table-viewer";
import { customAPIHandler } from "(apis)/api-interface/api-handler";

export default function OrderManager() {
    const [originalOrders, setOriginalOrders] = useState([]);
    const [orders, setOrders] = useState([]);
    const [skus, setSkus] = useState([]);
    const [courses, setCourses] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectStatus, setSelectStatus] = useState("All");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("PRE-MEDICAL");

    const fetchOrderDetails = async () => {
        setIsLoading(true);
        try {
            const response = await customAPIHandler("getOrders", null, null, selectedCategory);
            const data = response?.data?.data || [];
            setOrders(data);
            setOriginalOrders(data);
            setSkus(response?.data?.skus || []);
            setCourses(response?.data?.courses || []);
        } catch (error) {
            setIsLoading(false);
            setOrders([]);
            setOriginalOrders([]);
            console.error("Error fetching order details:", error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchOrderDetails();
    }, [selectedCategory]);

    const handleSelectStatusChange = (selectedStatus: string) => {
        setSelectStatus(selectedStatus);
        if (selectedStatus === "All") {
            setOrders(originalOrders);
            return;
        }
        const filteredOrders = originalOrders.filter((order) => {
            return order.OrderStatus === selectedStatus;
        });
        setOrders(filteredOrders);
    };

    const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
        if (e.target.value === "") {
            setOrders(originalOrders);
            return;
        }
        const filteredOrders = originalOrders.filter((order) => {
            return order.UserName.toLowerCase().includes(
                e.target.value.toLowerCase()
            );
        });
        setOrders(filteredOrders);
    };

    return (
        <div className="w-[95%] mx-auto h-full">
            <h1 className="text-3xl text-gray-700 font-bold">Order Manager</h1>
            <section className="my-5 antialiased">
                <div className="mx-auto">
                    {isLoading ? (
                        "Loading..."
                    ) : (
                        <div className="bg-white shadow-md sm:rounded-lg">
                            <TableTop
                                searchQuery={searchQuery}
                                handleSearchQueryChange={handleSearchQueryChange}
                                selectStatus={selectStatus}
                                handleSelectStatusChange={handleSelectStatusChange}
                                selectedCategory={selectedCategory}
                                setSelectedCategory={setSelectedCategory}
                            />
                            <TableView
                                orders={orders}
                                skus={skus}
                                courses={courses}
                                selectedCategory={selectedCategory}
                            />
                        </div>
                    )}
                </div>
            </section>
        </div>
    );
}

