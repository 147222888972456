import React from 'react';
import { ButtonFill, ButtonOutlined } from '../../../shared-components/ui/CustomButtons';
// import { ChangeOrderStatus, DeleteOrder, GetOrder } from '../../../(apis)/(shared-apis)/order-apis';
import toast from 'react-hot-toast';
import { Input } from '../../../shared-components/ui/CustomForm';
import { OrderAPI } from '../../../(apis)/(shared-apis)/order-apis';
import { customAPIHandler } from '(apis)/api-interface/api-handler';

export default function DenyOrderModal(props: any) {

    const [reason, setReason] = React.useState("");
    const DenyOrder = async () => {
        try {
            if (reason === "") {
                toast.error("Please Enter a Message");
                return;
            }
            const response = await customAPIHandler("changeStatusForBundlePurchase", "", 
                {
                    orderId: props.orderId,
                    newStatus: 'Denied',
                    message: reason,
                },
                props.entity
            ) 
            toast.success(response.data.message)
            window.location.reload();

        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message);
        }
    }

    return (
        <div
            id="createProductModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${props.active ? "" : "hidden"} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen backdrop-blur-sm !space-x-0`}
        >
            <div className="relative w-full max-w-2xl max-h-full p-4">
                <div className="relative p-4 bg-white rounded-lg shadow ">
                    <div className="flex text-md text-gray-900 font-semibold">
                        Deny Order
                    </div>
                    <div className="font-semibold text-gray-700 mt-2">
                        Please Enter the Reason for Denying this Order below:
                    </div>
                    <div className="flex w-full justify-center">
                        <Input
                            onChange={(name, value) => {
                                setReason(value);
                            }}
                            isError={false}
                            placeholder="Enter Reason"
                            value={reason}
                            name="reason"
                            type='text'
                        />
                    </div>
                    <div className="flex items-center justify-start md:justify-end gap-2 mt-4 flex-wrap">
                        <ButtonOutlined
                            handleClick={() => {
                                props.onCancel();
                            }}
                        >
                            Cancel
                        </ButtonOutlined>
                        <ButtonFill
                            handleClick={DenyOrder}
                        >
                            Deny Order
                        </ButtonFill>
                    </div>
                </div>
            </div>
        </div>

    )
}

