import { useFormik } from "formik";
import * as Yup from "yup";
import PaymentModal from "./modal";
import PaymentTable from "./table";
import CustomButton from "shared-components/form-fields/custom-button";
import { PlusOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { message } from "antd";
import { UserContext } from "state-management/user-context";
import identifyAndConvertNewImagesToBase64 from "utility/form/identifyAndConvertNewImagesToBase64";
import { generateAntdImageObjectFromUrl } from "utility/form/generate-antd-image-object-from-url";

const ManagePayments = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [serviceProviderData, setServiceProviderData] = useState([]);
  // Initial form values
  const initialValues = {
    type: "", // Internal or External
    serviceProvider: null, // External case only
    employee: null, // Internal case only
    amount: "", // Common field
    accountNumber: "", // Internal case only
    bankName: "", // Internal case only
    screenshot: null, // Optional in both cases,
    editPaymentId: "",
  };
  const { user } = useContext(UserContext);
  // Validation schema
  const validationSchema = Yup.object().shape({
    type: Yup.string().required("Type is required"),

    // Conditional validation for 'serviceProvider'
    serviceProvider: Yup.string().when("type", (type: any) => {
      if (type?.[0] === "External") {
        return Yup.string().required("Service provider is required");
      }
      return Yup.string().nullable();
    }),

    // Validation for 'amount'
    amount: Yup.number()
      .required("Amount is required")
      .positive("Amount must be positive"),
      accountNumber:Yup.string().required("Account number is required"),
      bankName: Yup.string().required("Bank name is required"),
    // Conditional validation for 'Payment' and 'PaymentBank'
    employee: Yup.string().when("type", (type: any) => {
      if (type?.[0] === "Internal") {
        return Yup.string().required("Employee is required");
      }
      return Yup.string().nullable();
    }),

    // Screenshot is optional
    screenshot: Yup.mixed().nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async(values, {resetForm}) => {
      const screensShotBase64 = await identifyAndConvertNewImagesToBase64(values.screenshot);
      const payload = {
        ...values,
        addedBy: user?.fullname,
        screenshot: screensShotBase64?.[0],
      };
      try {
        const { editPaymentId } = values;
        let res;
        if (isEditMode) {
          res = await apiRequest("updatePayment", payload, [editPaymentId]);
        } else {
          res = await apiRequest("addPayment", payload);
        }
        console.log("res: " + res);
        if (res.data?.isSuccess) {
          fetchPayments();
          message.success(res?.data?.message);
          setOpenModal(false);
          resetForm();
        }
      } catch (error) {
        message.error(error?.response?.data?.message || error?.message);
      }
    },
  });

  const fetchPayments = async () => {
    try {
      setIsLoading(true);
      const res = await apiRequest("getAllPaymentsServiceProviderAndEmployee");
      if (res.data?.isSuccess) {
        if(res.data?.payments?.length > 0) {
          const updatedRow = res.data?.payments?.map((item) => {
            return {
             ...item,
              label: item.name,
              value: item._id,
            };
          });
          setPaymentData(updatedRow);
        }else{
          setPaymentData([]);
        }
       if(res.data?.serviceProviders?.length > 0){
        const updatedRow = res.data?.serviceProviders?.map((item) => {
          return {
             ...item,
              label: item.name,
              value: item._id,
            };
          });
          setServiceProviderData(updatedRow);
        }else{
          setServiceProviderData([]);
        }
        if(res.data?.employees?.length > 0){
          const updatedRow = res.data?.employees?.map((item:any) => {
            return {
             ...item,
              label: item.name,
              value: item._id,
            };
          });
          setEmployeeData(updatedRow);
          }else{
            setEmployeeData([]);
          }
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchPayments();
  }, []);
  const handleEdit = (record) => {
    const { _id } = record;
    const img = generateAntdImageObjectFromUrl(record?.screenshotUrl);
    formik.setValues({
      ...record,
      editPaymentId: _id,
      screenshot : [img]
    });
    setIsEditMode(true);
    setOpenModal(true);
  };

  const handleDelete = async (recordId) => {
    try {
      const res = await apiRequest("deletePayment", null, [recordId]);
      if (res.data?.isSuccess) {
        message.success(res?.data?.message);
        fetchPayments();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    }
  };
  return (
    <div>
      <div className="">
        <div className={`flex justify-end items-end  gap-4`}>
          <CustomButton
            text="Add New Payment"
            onClick={() => {
              // setOpen(true)
              setOpenModal(true);
              setIsEditMode(false);
              formik.resetForm()
            }}
            icon={<PlusOutlined />}
          />
        </div>
        <div>
          <PaymentTable
            handleEdit={handleEdit}
            isLoading={isLoading}
            paymentData={paymentData}
            handleDelete={handleDelete}
          />
        </div>
      </div>
      <PaymentModal
        openModal={openModal}
        isEditMode={isEditMode}
        setOpenModal={setOpenModal}
        formik={formik}
        employeeData={employeeData}
        serviceProviderData={serviceProviderData}
      />
    </div>
  );
};

export default ManagePayments;
